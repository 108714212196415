export const getUpdatedUrl = (filters: any, fileType: any) => {
  if (window.location.href.search('live-monitoring') > -1) {
    let baseUrl: any = window.location.href
    baseUrl = baseUrl?.split('live-monitoring')
    baseUrl = baseUrl[0]
    baseUrl = `${baseUrl}live-monitoring`

    let country = ''
    let entryDetails = ''
    let submission = ''

    if (filters?.entry?.id && filters?.entry?.type) {
      entryDetails = `id=${filters?.entry?.id}&type=${filters?.entry?.type}`
      baseUrl = `${baseUrl}?${entryDetails}`
    }
    if (filters?.submissionType) {
      let type = filters?.submissionType
      type = type?.toLowerCase()
      submission = `submission=${type}`
      baseUrl = `${baseUrl}?${submission}`
    }
    if (filters?.countries?.length > 0) {
      country = `countryCode=${filters?.countries[0]?.countryCode}&countryName=${filters?.countries[0]?.countryName}`
      if (entryDetails.length > 0 || submission.length > 0) {
        baseUrl = `${baseUrl}&${country}`
      } else {
        baseUrl = `${baseUrl}?${country}`
      }
    }
    return baseUrl
  }
}

export const getObjFromUrlQueryParams = () => {
  const seperateBaseUrl = window.location.href?.split('live-monitoring?')
  let res: any = {entry: null, country: null, submission: null, extraInfo: null, searchParam: null}
  const queryParams: any = seperateBaseUrl[1]?.split('&')
  if (queryParams) {
    queryParams.forEach((item: any) => {
      const currentItem = item?.split('=')
      currentItem.forEach((value: any) => {
        if (value === 'id') {
          res.entry = {...res.entry, id: currentItem[1]}
        } else if (value === 'type') {
          res.entry = {...res.entry, type: currentItem[1]}
        } else if (value === 'countryCode') {
          res.country = {...res.country, countryCode: currentItem[1]}
        } else if (value === 'countryName') {
          res.country = {...res.country, countryName: currentItem[1]}
        } else if (value === 'submission') {
          res = {...res, submission: currentItem[1]?.toUpperCase()}
        }

        // Read search Param query
        // else if (value === 'searchParam') {
        //   res = {...res, searchParam: currentItem[1]}
        // }

        // Extra filter read from query
        // else if (value === 'irrelevant') {
        //   res.extraInfo = {...res.extraInfo, irrelevant: currentItem[1]}
        // } else if (value === 'whiteList') {
        //   res.extraInfo = {...res.extraInfo, whiteList: currentItem[1]}
        // } else if (value === 'ignored') {
        //   res.extraInfo = {...res.extraInfo, ignored: currentItem[1]}
        // } else if (value === 'showOnlyMatches') {
        //   let booleanQuery = currentItem[1]
        //   let boolOutput = JSON.parse(booleanQuery)
        //   res.extraInfo = {...res.extraInfo, showOnlyMatches: boolOutput}
        // }
      })
    })
  }
  if (res?.country && Object.keys(res?.country).length > 0) {
    res.country = [res.country]
  }
  return res
}

export const isFilteringByNewEntryId = (currentQueryParams: any, filters: any) => {
  return (
    Object.keys(currentQueryParams).length > 0 &&
    currentQueryParams?.entry &&
    currentQueryParams?.entry?.id !== filters?.entry?.id
  )
}

export const isFilteringByNewSubmissionType = (currentQueryParams: any, filters: any) => {
  return (
    Object.keys(currentQueryParams).length > 0 &&
    currentQueryParams?.submission &&
    (!filters?.submissionType || filters?.submissionType !== currentQueryParams?.submission)
  )
}

export const isFilteringByNewCountry = (currentQueryParams: any, filters: any) => {
  return (
    Object.keys(currentQueryParams).length > 0 &&
    !currentQueryParams?.entry &&
    !currentQueryParams?.submission &&
    currentQueryParams?.country &&
    currentQueryParams?.country?.length > 0 &&
    currentQueryParams?.country[0]?.countryCode !== filters?.countries[0]?.countryCode
  )
}

// Search Param Condition
// export const isFilteringBySearchParam = (currentQueryParams: any, filters: any) => {
//   return (
//     Object.keys(currentQueryParams).length > 0 &&
//     currentQueryParams?.searchParam &&
//     !filters?.searchParam
//   )
// }

export const onlySelectBefore = (currentExtraFilter: any) => {
  let check = false
  Object.values(currentExtraFilter).forEach((item: any) => {
    if (item?.length > 0 && item?.search('Only') !== -1) {
      check = true
    }
  })
  return check
}

export const getExtraFilterUrl = (extraFilters: any) => {
  let extraFilterUrl = ''
  if (extraFilters && Object.keys(extraFilters).length > 0) {
    Object.entries(extraFilters).forEach((item) => {
      if (item[1] === `${item[0]}Without`) {
        extraFilterUrl = `${
          extraFilterUrl.length === 0 ? extraFilterUrl : extraFilterUrl + '&'
        }not-${item[0] === 'hidden' ? 'ignored' : item[0]}`
      } else if (item[1] === `${item[0]}Only`) {
        extraFilterUrl = `${
          extraFilterUrl.length === 0 ? extraFilterUrl : extraFilterUrl + '&'
        }only-${item[0] === 'hidden' ? 'ignored' : item[0]}`
      } else if (item[0] === 'latestItems' && item[1]) {
        extraFilterUrl = `${
          extraFilterUrl.length === 0 ? extraFilterUrl : extraFilterUrl + '&'
        }orderBy=created_at&sortedBy=desc`
      }
    })
  }
  return extraFilterUrl
}

export const removeSearchInputValue = () => {
  if (document.getElementById('liveMonitoring_search-input')) {
    const inputElement: any = document.getElementById('liveMonitoring_search-input')
    inputElement.value = ''
  }
}
