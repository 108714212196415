import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  montitoringFilters: null,
}

const montitoringFiltersSlice = createSlice({
  name: 'montitoringFilters',
  initialState,
  reducers: {
    setMontitoringFilters: (state, action) => {
      state.montitoringFilters = action.payload.filters
    },
  },
})

export const {setMontitoringFilters} = montitoringFiltersSlice.actions

export default montitoringFiltersSlice.reducer
